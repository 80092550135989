import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

export function Hero() {
  return (
    <div className="hero-img-wrap">
      <StaticImage
        layout="constrained"
        aspectRatio={2 / 1}
        alt="A stand of pine trees"
        src="../images/pine-trees.jpg"
        imgStyle={{ borderRadius: '.5rem' }}
      />
    </div>
    
  )
}