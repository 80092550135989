import * as React from 'react'
import Layout from '../components/layout'
import { Hero } from '../components/about-hero'

const IndexPage = () => {
  return (
    <Layout pageTitle="">
      <div class="flex-container-frontpage">

      <div class="flex-item-frontpage">
        <Hero />
      </div>

        <div class="flex-item-frontpage">
          <blockquote class="quote">
            "We’re Polymaths. There’s no creative TYPES. We’re all Creative. 
            So what if some people call you a digital artist and other people 
            call you a computer scientist. Who cares what people think? 
            You’re a Polymath and you can learn and do anything the hell you want."
            <br></br>
            <br></br>
            - Epona Schweer 
            - On Creatiivty (blog) Nov. 26, 2012 - indiebits.com
          </blockquote>
        </div>
      </div>
    </Layout>

  )
}

export default IndexPage